/* Estilos globales */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Estilos para los botones */
.MuiButton-root {
  text-transform: none;
}

/* Asegura que todos los botones tengan el mismo color de fondo */
.MuiButton-contained {
  background-color: #6c008d !important;
  color: white !important;
}

.MuiButton-contained:hover {
  background-color: #6c008d !important;
}

/* Estilos para el modo oscuro */
.dark-mode .MuiButton-contained {
  background-color: rgb(164, 66, 245) !important;
  color: #121212 !important;
}

.dark-mode .MuiButton-contained:hover {
  background-color: rgb(164, 66, 245) !important;
}